import React, { Component } from 'react'

class thanks extends Component {
  render() {
    return (
      <div>
        <div className="wrapper">
            <h1>Thank you</h1>
        </div>
      </div>
    )
  }
}

export default thanks
