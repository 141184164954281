import React, {Component} from 'react'
import './components/nav.css';
import Main from './components/main';
import Modal from './components/Modal';


 class App extends Component {
constructor(props){
    super(props);
    this.state={
      menuOpen:false,
    }

    this.state = {
      isShowing: false
  }
  }
  
  handleMenuClick() {
    this.setState({menuOpen:!this.state.menuOpen});
  }
  
  handleLinkClick() {
    this.setState({menuOpen: false});
  }



  openModalHandler = () => {
    this.setState({
        isShowing: true
    });
}

closeModalHandler = () => {
    this.setState({
        isShowing: false
    });
}
  
  render(){

    const menu = [
      <button className="open-modal-btn" onClick={this.openModalHandler}>CONTACT</button>

    ]
    const menuItems = menu.map((val,index)=>{
      return (
        <MenuItem 
          key={index} 
          delay={`${index * 0.1}s`}
          onClick={()=>{this.handleLinkClick();}}>{val}</MenuItem>)
    });
    
    return(
      <div>
        <div className="header">
          <MenuButton className="navhide" open={this.state.menuOpen} onClick={()=>this.handleMenuClick()}/>
          <a href = "/"><h1>ZUNG DINH</h1></a>
        </div>
        <div className="mainheader">
          <a href = "/"><h1 className="mh">ZUNG DINH</h1></a>
          <div className="rmh">
            <button className="open-modal-btn" onClick={this.openModalHandler}>CONTACT</button>
          </div>

          <Modal
          className="modal"
          show={this.state.isShowing}
          close={this.closeModalHandler}>

          <form accept-charset="UTF-8" name="contact" method="post" action="https://api.formbucket.com/f/buk_bxSGX2HpHV2FXz1K6S2mdxKm">
          <input type="hidden" name="form-name" value="contact" />
            <label>FULL NAME</label>
            <input type="text" name="name" />
            <label>YOUR EMAIL</label>
            <input type="email" name="email"/>
            <label for="comments"> YOUR MESSAGE </label>
            <textarea name="message"></textarea>
            <button type="submit" name="submit" id="submit" value="SUBMIT">SUBMIT</button> 
          </form>   

          </Modal>
        </div>
        
        <Menu open={this.state.menuOpen}>
          {menuItems}
        </Menu>
        

        <Main />

      </div>
      
    )
  }
}

/* MenuItem.jsx*/
class MenuItem extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover:false,
    }
  }
  
  handleHover(){
    this.setState({hover:!this.state.hover});
  }
  
  render(){

    return(
      <div className="menuanime">
        <div className="menuItem"
          onMouseEnter={()=>{this.handleHover();}} 
          onMouseLeave={()=>{this.handleHover();}}
          onClick={this.props.onClick}
        >
          {this.props.children}  
        </div>
      <div className="insideline" />
    </div>  
    )
  }
}

/* Menu.jsx */
class Menu extends React.Component {
  constructor(props){
    super(props);
    this.state={
      open: this.props.open? this.props.open:false,
    }
  }
    
  componentWillReceiveProps(nextProps){
    if(nextProps.open !== this.state.open){
      this.setState({open:nextProps.open});
    }
  }
  
  render(){
    const styles={
      menudropdown: {
        height: this.state.open? '100%': 0,
      }
    }
    return(
      <div className="menudropdown" style={styles.menudropdown}>
        {
          this.state.open?
            <div className="menuList">
              {this.props.children}
            </div>:null
        }
      </div>
    )
  }
}

/* MenuButton.jsx */
class MenuButton extends React.Component {
  constructor(props){
    super(props);
    this.state={
      open: this.props.open? this.props.open:false,
      color: this.props.color? this.props.color:'black',
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.open !== this.state.open){
      this.setState({open:nextProps.open});
    }
  }
  
  handleClick(){
  this.setState({open:!this.state.open});
  }
  
  render(){
    const styles = {
      container: {
        height: '62px',
        width: '32px',
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '10px 42px',
      },
      line: {
        height: '2px',
        width: '20px',
        background: '#ccc',
        transition: 'all 0.2s ease',
      },
      lineTop: {
        transform: this.state.open ? 'rotate(45deg)':'none',
        transformOrigin: 'top left',
        marginBottom: '5px',
      },
      lineMiddle: {
        opacity: this.state.open ? 0: 1,
        transform: this.state.open ? 'translateX(-16px)':'none',
      },
      lineBottom: {
        transform: this.state.open ? 'translateX(-1px) rotate(-45deg)':'none',
        transformOrigin: 'top left',
        marginTop: '5px',
      },       
    }
    return(
      <div style={styles.container} 
        onClick={this.props.onClick ? this.props.onClick: 
          ()=> {this.handleClick();}}>
        <div style={{...styles.line,...styles.lineTop}}/>
        <div style={{...styles.line,...styles.lineMiddle}}/>
        <div style={{...styles.line,...styles.lineBottom}}/>
      </div>
    )
  }
}


export default App;