import React from 'react';
import { Switch, Route } from 'react-router-dom';


import landing from './landing';
import thanks from  './thanks';

const Main = () => (
        <Switch>
        <Route exact path = "/" component ={landing} />
        <Route path = "/thanks" component ={thanks} />

        </Switch>
)

export default Main;

