import React, { Component } from 'react'
import './components.css';
import ScrollAnimation from 'react-animate-on-scroll';
import AnchorLink from 'react-anchor-link-smooth-scroll'



class landing extends Component {
  render() {
    return (
      <div className="all-bod">
        <div className="landing-bod">

          <div className="col-lg-12">
            <div className ="row">
            <div className="mtop"></div>

            <div className="col-lg-1 col-md-1 col-sm-1 blue2">
              <hr className="hr1"></hr>
              <p><AnchorLink href="#about" offset='100'>ABOUT</AnchorLink></p>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 blue2"></div>

            <div className="col-lg-8 col-md-8 col-sm-8">
              <div className="wrapper">
                <h1>Z</h1>
              </div>
              <p className="sub-tag">PORTFOLIO</p>
            </div>

            <div className="col-lg-1 col-md-1 col-sm-1 blue"></div>
            <div className="col-lg-1 col-md-1 col-sm-1 blue">
            <hr className="hr2"></hr>
              <p><AnchorLink href="#projects">PROJECTS</AnchorLink></p>
            </div>

          </div>
      </div>
    </div>
        <div id="about" className="parallax"></div>
          <div className="landing-bod2">
            <div className="col-lg-12">
              <div className="row">
              
                <div className="col-lg-4 col-md-4 col-sm-12" >
                  <ScrollAnimation animateIn='fadeIn' delay={260}>
                    <h3 className="test-scroll">ABOUT</h3>
                  </ScrollAnimation>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className="b-border">
                    <p>Hi, My name is Zung and I am a developer here in San Diego, California. I love to build websites and small applications with whatever tools I can get my hands on. 
                    I am constantly coding and I am always trying to find new ways to better myself as a developer.</p>
                    <br></br>
                    <p>
                    html/css <span>|</span> jsx <span>|</span> ejs <span>|</span> bootstrap <span>|</span> react <span>|</span> express <span>|</span> mongodb <span>|</span> node <span>|</span> mysql <span>|</span> git <span>|</span> svn <span>|</span> photoshop <span>|</span> indesign <span>|</span> illustrator
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div id="projects" className="parallax"></div>
          <div className="landing-bod3">
            <div className="col-lg-12">
              <ScrollAnimation animateIn='fadeIn' delay={260}>
                <h3>PROJECTS</h3>
              </ScrollAnimation>
              <div className="row">

              <a href="http://zungdinh.com/video-player/" target="blank" alt="" className="col-lg-4 col-md-6 col-sm-6 col-xs-12 descrip">           
                <div className="hover_image">
                  <img src="/images/videoplayer.png" alt="" />
                    <figcaption>
                      <div className="inner">
                        <h6>React Video Finder</h6>
                        <div className="desc">This react application displays the video you want by fetching
                        data from a Youtube API. When the user input a title in the search field, 5 videos that closely match to the title will populate.</div>
                      </div>
                    </figcaption>
                  <h6>React Video Finder</h6>
                </div>
              </a>

              <a href="http://zungdinh.com/weather-app/" target="blank" alt="" className="col-lg-4 col-md-6 col-sm-6 col-xs-12 descrip">           
                <div className="hover_image">
                  <img src="/images/weatherfinder.png" alt="" />
                    <figcaption>
                      <div className="inner">
                        <h6>React Weather App</h6>
                        <div className="desc">This application was built to retrieve data and display its
                        weather temperatures when inputting a name of a city in the form fields.
                        The application was built by React and uses the Weather API</div>
                      </div>
                    </figcaption>
                  <h6>React Weather App</h6>
                </div>
              </a>

              <a href="http://zungdinh.com/VSTDA" target="blank" alt="" className="col-lg-4 col-md-6 col-sm-6 col-xs-12 descrip">           
                <div className="hover_image">
                  <img src="/images/vstda.png" alt="" />
                    <figcaption>
                      <div className="inner">
                        <h6>VSTDA - REACT</h6>
                        <div className="desc">A To-Do application built by React. Users
                        can input what they feel needs to be done and set
                        the level of priorities for each item.</div>
                      </div>
                    </figcaption>
                  <h6>VSTDA - REACT</h6>
                </div>
              </a>


              <a href="http://www.dreamhomesmagazine.com/" target="blank" alt="" className="col-lg-4 col-md-6 col-sm-6 col-xs-12 descrip">           
                <div className="hover_image">
                  <img src="/images/dh.png" alt="" />
                    <figcaption>
                      <div className="inner">
                        <h6>Dream Homes Website</h6>
                        <div className="desc">My duty for this company was to help build responsive web pages with html/css, bootstrap and a bit of javacript. I was also tasked to help debug any issues that might arise. </div>
                      </div>
                    </figcaption>
                  <h6>Dream Homes Website</h6>
                </div>
              </a>

              <a href="https://symposium2020.careasy.org" target="blank" alt="" className="col-lg-4 col-md-6 col-sm-6 col-xs-12 descrip">           
                <div className="hover_image">
                  <img src="/images/symposium.jpg" alt="" />
                    <figcaption>
                      <div className="inner">
                        <h6>CARS Symposium 2020</h6>
                        <div className="desc">For this project, I was tasked to design and build a fully functional website for a CARS annual event.
                        The tools I've used to build this site was primary html/css, javascript</div>
                      </div>
                    </figcaption>
                  <h6>CARS Symposium 2020</h6>
                </div>
              </a>

              <a href="http://zungdinh.com/MAUDE%20&%20SONS/index.html" target="blank" alt="" className="col-lg-4 col-md-6 col-sm-6 col-xs-12 descrip">           
                <div className="hover_image">
                  <img src="/images/m&s.png" alt="" />
                    <figcaption>
                      <div className="inner">
                        <h6>Maude & Sons Website</h6>
                        <div className="desc">This was one of the many projects that I've made for pure practice only.
                         I've designed and built this project just to see how creative I can get within a short period of time. Tools use: html/css, javascript</div>
                      </div>
                    </figcaption>
                    <h6>Maude & Sons Website</h6>
                </div>
              </a>


              <a href="https://tacanow.careasy.org/home" target="blank" alt="" className="col-lg-4 col-md-6 col-sm-6 col-xs-12 descrip">           
              <div className="hover_image">
                <img src="/images/taca.jpg" alt="" />
                  <figcaption>
                    <div className="inner">
                      <h6>TACA</h6>
                      <div className="desc"> I was tasked to build this donation landing page to resemble the client's original homepage. Tools use: html/css, javascript </div>
                    </div>
                  </figcaption>
                  <h6>The Autism Community in Action</h6>
              </div>
            </a>

            <a href="https://garysinisefoundation.careasy.org/home" target="blank" alt="" className="col-lg-4 col-md-6 col-sm-6 col-xs-12 descrip">           
            <div className="hover_image">
              <img src="/images/garysinise.jpg" alt="" />
                <figcaption>
                  <div className="inner">
                    <h6>Gary Sinise Foundation</h6>
                      <div className="desc"> I was tasked to build this donation landing page to resemble the client's original homepage. Tools use: html/css, javascript </div>
                      </div>
                </figcaption>
                <h6>Gary Sinese Foundation</h6>
            </div>
          </a>




        <a href="https://redsoxfoundation.careasy.org/home" target="blank" alt="" className="col-lg-4 col-md-6 col-sm-6 col-xs-12 descrip">           
        <div className="hover_image">
          <img src="/images/redsox.jpg" alt="" />
            <figcaption>
              <div className="inner">
                <h6>Red Sox Foundation</h6>
                <div className="desc"> I was tasked to build this donation landing page to resemble the client's original homepage. Tools use: html/css, javascript </div>
                </div>
            </figcaption>
            <h6>Red Sox Foundation</h6>
        </div>
      </a>







 











              <div className="parallax2"/> </div>
              

              <div className="row social-wrapper">
                
                  <div className="smedia"> <a href="https://www.linkedin.com/in/zung-dinh-790470b9/">
                   <img src="/images/link3.png" alt="linkedin" id="social" width="100%"  />
                   </a>
                  </div>

                  <div className="smedia"><a href="https://github.com/Zoommie">
                   <img src="/images/github3.png" alt="github" id="social" width="100%" />
                   </a>
                  </div>

                  <div className="smedia"><a href="https://www.behance.net/Zoommie">
                   <img src="/images/behance3.png" alt="behance" id="social" width="100%" />
                   </a>
                  </div>

              </div>

             
            </div>
          
          </div>

      </div>
    )
  }
}
export default landing;